.container-stats{
    display: flex;
    align-items: center;
    justify-content: center;
}



.button{
    width: 30px;
    height: 30px;
    text-align: center;
    /* vertical-align: middle; */
    padding: 3px 5px 7px 5px ;
    border: 1px solid #000;
    color:#fff;
    border-radius: 50%;
    align-self: stretch;
    margin: 0px 5px 0px 5px;
}


.button-red{
    background-color: #ff0000;
}

.button-green{
    background-color: #008000;
}

.button-blue{
    background-color:#0000ff;
}