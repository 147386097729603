.leaflet-div-icon{
    /* display:inline-block; */
    width: 1px;
    height: 1px;
    /* text-align: center;
    padding: 5px;
    border: 1px solid #000;
    color:#fff;
    border-radius: 50%;
    background: #dc3545; */
}

.circle{
    /* display:inline-block; */
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    border: 1px solid #000;
    color:#fff;
    border-radius: 50%;
    background:#ff0000;
}

.novisitado{
    background:#0000ff;
}

.compra{
    background:#008000;
}

