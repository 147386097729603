.container-stats{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button{
    width: 30px;
    height: 30px;
    text-align: center;
    /* vertical-align: middle; */
    padding: 3px 5px 7px 5px ;
    border: 1px solid #000;
    color:#fff;
    border-radius: 50%;
    /* align-self: stretch; */
    margin: 0px 5px 0px 5px;
}

.button-red{
    background-color: #ff0000;
}

.button-green{
    background-color: #008000;
}

.button-blue{
    background-color:#0000ff;
}

.button-black {
    background-color: #000000;
}

.btn {
    border-radius: 10px;
    padding: 3px 5px 7px 5px;
    border: 1px solid #909090;
    background-color: #dcdcdc;
}

.button-pedido {
    width: 30px;
    text-align: center;
    color: #fff;
    background-color: #000000;
    border-radius: 10px;
    padding: 3px 5px 7px 5px;
    margin-right: 3px;
}

.button-ttl {
    margin-right: 15px;
}

.button-promedio {
    margin-right: 20px;
}